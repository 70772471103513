<template>
  <v-container
    id="login"
    fluid
    tag="section"
  >
    <v-navigation-drawer
      absolute
      width="100%"
      src="assets/fondo.jpg"
      permanent
    >
      <br><br><br><br><br>
      <v-row justify="center">
        <base-material-card
          icon="mdi-alert-circle"
          color="primary"
          title="ERROR"
          light
          max-width="80%"
          width="400"
          class="px-5 py-3"
        >
          <v-card-text class="text-center">
            <div class="text-center">
              <v-img
                src="assets/panda.png"
                max-width="350"
              />
            </div>
            <br>
            <v-btn
              id="btnLog"
              tile
              block
              color="primary"
              @click="regresarPagina()"
            >
              <v-icon left>
                mdi-scale-balance
              </v-icon>
              Regresar a la vista anterior
            </v-btn>
          </v-card-text>
        </base-material-card>
      </v-row>
    </v-navigation-drawer>
  </v-container>
</template>

<script>
  export default {
    name: 'Login',
    components: {
    },

    data: () => ({
      urlFondo: '',
      urlLogo: '',
    }),
    mounted () {
      this.$store.commit('SET_VALOR', true)
    },
    methods: {
      regresarPagina () {
        this.$router.go(-1)
      },
    },
  }
</script>
